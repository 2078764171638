import React, { useEffect } from "react";

const appendInput = (form, key, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  form.appendChild(input);
};

const PostRedirectBccAft = ({ action, creq, termUrl, threeDSSessionData }) => {
  console.log({ action, creq, termUrl, threeDSSessionData });

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = action;
    form.style.display = "none";

    appendInput(form, "creq", creq);
    appendInput(form, "termUrl", termUrl);
    appendInput(form, "threeDSSessionData", threeDSSessionData);

    console.log(form);

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [action, creq, termUrl, threeDSSessionData]);

  return null;
};

export default PostRedirectBccAft;
